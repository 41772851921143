var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticStyle: { width: "100%", height: "100%", border: "1px solid red" },
      attrs: { id: "abc" },
      on: {
        mouseup: _vm.mouseupHandler,
        mousemove: _vm.mousemoveHandler,
        mousedown: _vm.mousedownHandler,
      },
    },
    [
      _c(
        "v-stage",
        { ref: "stage", attrs: { config: _vm.stageSize } },
        [
          _c(
            "v-layer",
            _vm._l(_vm.abc.abc.rects, function (rect, index) {
              return _c("v-rect", {
                key: index,
                attrs: { config: rect },
                on: {
                  mousedown: (e) => _vm.handleDragStart(e, rect),
                  mouseup: (e) => _vm.handleDragEnd(e, rect),
                  mouseover: _vm.mouseover,
                  mouseleave: _vm.mouseleave,
                },
              })
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }