<template>
  <div
    style="width:100%;height:100%;border:1px solid red"
    id="abc"
    @mouseup="mouseupHandler"
    @mousemove="mousemoveHandler"
    @mousedown="mousedownHandler"
  >
    <v-stage ref="stage" :config="stageSize">
      <v-layer>
        <!--         <v-line
          @mousedown="e => handleDragStart(e, line)"
          @mouseup="e => handleDragEnd(e, line)"
          @mouseover="mouseover"
          @mouseleave="mouseleave"
          v-for="(line, index) in lines"
          :key="index"
          :config="line"
        /> -->
        <v-rect
          @mousedown="e => handleDragStart(e, rect)"
          @mouseup="e => handleDragEnd(e, rect)"
          @mouseover="mouseover"
          @mouseleave="mouseleave"
          v-for="(rect, index) in abc.abc.rects"
          :key="index"
          :config="rect"
        />
      </v-layer>
    </v-stage>
  </div>
</template>

<script>
const width = window.innerWidth
const height = window.innerHeight

export default {
  data() {
    return {
      stageSize: {
        width: width,
        height: height
      },
      text: '',
      isDragging: false,
      lines: [],
      abc:{
        abc:{
          rects:[]
        }
      },
      painting: false
    }
  },
  methods: {
    mouseover() {
      if (!this.painting) {
        document.getElementById('abc').style.cursor = 'move'
      }
    },
    mouseleave() {
      if (!this.painting) {
        document.getElementById('abc').style.cursor = 'default'
      }
    },
    handleDragStart(e, data) {
      if (!this.painting) {
        data.stroke = 'red'
      }

      this.isDragging = true
    },
    handleDragEnd(e, data) {
      if (!this.painting) {
        data.stroke = 'black'
      }
      this.isDragging = false
    },
    writeMessage(message) {
      this.text = message
    },
    handleMouseOut(event) {
      this.writeMessage('Mouseout triangle')
    },
    handleMouseMove(event) {
      const mousePos = this.$refs.stage.getNode().getPointerPosition()
      const x = mousePos.x - 190
      const y = mousePos.y - 40
      this.writeMessage('x: ' + x + ', y: ' + y)
    },
    mousedownHandler(e) {
      if (this.isDragging) return
      //	是否开始画线
      this.painting = true
      // 线
      
      /* const newLine = {
        x: 0,
        y: 0,
        stroke: 'black',
        draggable: true,
        strokeWidth: 4,
        points: [e.offsetX, e.offsetY],
        lineCap: 'round',
        lineJoin: 'round'
      } 
      this.lines.push(newLine) */
      //矩形
      const newRect = {
        x: e.offsetX,
        y: e.offsetY,
        width: 0,
        height: 0,
        stroke: 'black',
        draggable: true,
        strokeWidth: 4
      }
      this.abc.abc.rects.push(newRect)
    },
    mousemoveHandler(e) {
      if (this.isDragging) return
      if (this.painting) {
        /* const lastLine = this.lines[this.lines.length - 1] */
        //笔
        /* lastLine.points = lastLine.points.concat([e.offsetX, e.offsetY]) */
        //直线
        /* lastLine.points = [lastLine.points[0], lastLine.points[1], e.offsetX, e.offsetY] */
        const lastRects = this.abc.abc.rects[this.abc.abc.rects.length - 1]
        lastRects.width=e.offsetX-lastRects.x
        lastRects.height=e.offsetY-lastRects.y
      }
    },
    mouseupHandler() {
      
      //结束画线（停止了鼠标滑动事件中的操作）
      this.painting = false
/*       this.$nextTick(()=>{
        var dataURL = this.$refs.stage.getStage().toDataURL();
        console.log(dataURL)
      }) */
    }
  }
}
</script>
